export function formatReadingTime(minutes) {
  return `${minutes} min de lecture`
}

export function formatPostDate(date) {
  if (typeof Date.prototype.toLocaleDateString !== "function") {
    return date
  }

  return new Date(date).toLocaleDateString("fr", {
    day: "numeric",
    month: "long",
    year: "numeric",
  })
}
