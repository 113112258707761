import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

import Layout from '../components/layout'
import MainBio from '../components/main-bio'
import Pills from '../components/pills'
import Section from '../components/section'
import SEO from '../components/seo'
import Toolbar from '../components/toolbar'
import { formatPostDate, formatReadingTime } from '../utils/dates'

import Footer from '../components/footer'
import './blog-listing.css'

const BlogIndexPage = ({ data: { allMdx } }) => {
  return (
    <Layout>
      <Toolbar />
      <SEO />
      <Section centered name="main-bio">
        <MainBio />
      </Section>

      {allMdx.nodes.map((post) => (
        <Section key={post.fields.slug} name={post.fields.slug} centered>
          <Link to={post.fields.slug} className="blog-listing">
            <h1>{post.frontmatter.title}</h1>

            <p>
              <span>{`${post.frontmatter.author} - `}</span>
              {formatPostDate(post.frontmatter.date)}
              {` − ${formatReadingTime(post.timeToRead)}`}
            </p>

            <Pills items={post.frontmatter.categories} />

            <br />
            {post.frontmatter.cover && (
              <Img fluid={post.frontmatter.cover.childImageSharp.sizes} />
            )}

            <p>{post.frontmatter.description}</p>
          </Link>
        </Section>
      ))}

      <Footer />
    </Layout>
  )
}
export default BlogIndexPage

export const query = graphql`
  query BlogIndex {
    allMdx(
      filter: { fields: { published: { eq: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        fields {
          slug
        }
        timeToRead
        frontmatter {
          title
          description
          categories
          author
          cover {
            childImageSharp {
              sizes(maxWidth: 630) {
                ...GatsbyImageSharpSizes
              }
            }
          }
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`
