import React from 'react'
import { Link } from 'gatsby'

import { capitalize } from '../utils/string'

import './pills.css'

function cssSafe(str) {
  return encodeURIComponent(str.toLowerCase()).replace(/%[0-9A-F]{2}/gi, '')
}

const Pills = ({ items }) => {
  return (
    <div className="pills">
      {(items || []).map(item => (
        <Link to={`/tags/${item}`} className={`pill pill--${cssSafe(item)}`} key={item} style={{ marginRight: 10 }}>
          {item}
        </Link>
      ))}
    </div>
  )
}

export default Pills
